/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import {
  Home,
  Bed,
  Euro,
  Image,
  Mail,
  Menu,
  X,
  MapPin,
  ChevronDown,
  ParkingSquare,
  Wifi,
  TreePine,
  Tv,
} from "lucide-react";
import { AnimatePresence, motion, useScroll, useSpring } from "framer-motion";
import Logo from "./imm/logo_small.png";
import Base from "./imm/base.jpg";
import Bagno from "./imm/bagno.jpg";
import Camera2 from "./imm/camera2.jpg";
import Fuori from "./imm/fuori.jpg";
import Sala1 from "./imm/sala1.jpg";
import Apparecchiato from "./imm/apprecchiato.jpg";
import FuroiPrimavera from "./imm/fuoriprimavera.JPG";
import Portone from "./imm/portone.jpg";
import Camera1 from "./imm/camera1.jpg";
import Camera3 from "./imm/camera3.jpg";
const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, { stiffness: 80, damping: 15 });

  const menuItems = [
    { text: "Home", icon: <Home size={16} />, path: "#home" },
    { text: "Appartamenti", icon: <Bed size={16} />, path: "#appartamenti" },
    { text: "Tariffe", icon: <Euro size={16} />, path: "#tariffe" },
    { text: "Gallery", icon: <Image size={16} />, path: "#gallery" },
    { text: "Contatti", icon: <Mail size={16} />, path: "#contatti" },
  ];

  const handleMenuClick = (path) => {
    document.querySelector(path).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setIsMenuOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Scroll Progress Bar */}
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-pink-500 origin-left"
        style={{ scaleX }}
      />
      <header className="fixed top-0 w-full bg-white shadow-md z-50">
        <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <motion.img
              src={Logo}
              alt="B&B Amarena Logo"
              className="w-40 h-24 object-contain"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
            />
          </div>

          <div className="hidden md:flex space-x-6">
            {menuItems.map((item) => (
              <motion.a
                key={item.text}
                onClick={() => handleMenuClick(item.path)}
                className="flex items-center space-x-1 text-gray-600 hover:text-pink-600 transition-colors"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05, color: "#db2777" }}
                transition={{ delay: 0.1 }}
              >
                {item.icon}
                <span>{item.text}</span>
              </motion.a>
            ))}
          </div>

          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-gray-600"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>

        {isMenuOpen && (
          <motion.div
            className="md:hidden bg-white border-t"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.5 }}
          >
            {menuItems.map((item) => (
              <motion.a
                key={item.text}
                onClick={() => handleMenuClick(item.path)}
                className="flex items-center space-x-2 px-4 py-3 hover:bg-gray-50"
              >
                {item.icon}
                <span>{item.text}</span>
              </motion.a>
            ))}
          </motion.div>
        )}
      </header>

      <main className="pt-20">{children}</main>

      <footer className="bg-gray-800 text-white py-8 mt-16">
  <div className="container mx-auto px-4">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
     {/*  <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      > */}
       {/*  <h3 className="text-xl font-bold mb-4">B&B Amarena</h3> */}
        {/* Removed "Tutti i diritti riservati" from here */}
      {/* </motion.div> */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 1 }}
      >
        <h3 className="text-xl font-bold mb-4">Contatti</h3>
        <p>Email: bbamarena.carpineti@gmail.com</p>
        <p>Tel: +39 334 781 3920</p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 1 }}
      >
        <h3 className="text-xl font-bold mb-4">Seguici</h3>
        <div className="flex space-x-4">
          <a
            href="https://www.facebook.com/bbamarena/"
            className="hover:text-pink-400"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/bbamarena/"
            className="hover:text-pink-400"
          >
            Instagram
          </a>
        </div>
      </motion.div>
    </div>

    {/* Centered Copyright Text */}
    <div className="text-center mt-8">
      <p>Copyright © {new Date().getFullYear()} - Tutti i diritti riservati - B&B Amarena. - by{" "}
      <a href="https://fiverr.com/thomasmedici">Medici Thomas</a>.</p>
    </div>
  </div>
</footer>
    </div>
  );
};

const HomePage = () => {
  const arrowAnimation = {
    animate: {
      y: [0, 8, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  const handleScrollDown = () => {
    const nextSection = document.getElementById("features");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="home" className="space-y-16">
      <section className="relative h-[90vh]">
        {/* Background image */}
        <div className="h-[90vh] w-full">
          <motion.img
            src={Base}
            alt="B&B Amarena Background"
            className="h-full w-full object-cover object-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        </div>

        {/* Dark Overlay */}
        <motion.div
          className="absolute inset-0 bg-black/40"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />

        {/* Content */}
        <motion.div
          className="absolute inset-0 flex items-center justify-center text-center text-white"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <div className="space-y-4">
            <h1 className="text-4xl md:text-6xl font-bold">
              Benvenuti al B&B Amarena
            </h1>
            <p className="text-xl">
              B&B Amarena: un'esperienza che unisce storia e natura.
            </p>
          </div>
        </motion.div>

        {/* Animated Arrow */}
        <motion.div
          id="features"
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white cursor-pointer"
          variants={arrowAnimation}
          animate="animate"
          onClick={handleScrollDown}
          whileTap={{ scale: 0.9 }}
        >
          <ChevronDown className="w-8 h-8" />
        </motion.div>
      </section>

      <motion.section
        className="container mx-auto px-4 pt-16"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ staggerChildren: 0.2 }}
      >
        <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            className="text-center p-6 bg-white rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
          >
            <motion.div>
              <Bed className="w-12 h-12 mx-auto text-pink-600 mb-4" />
            </motion.div>
            <h3 className="text-xl font-bold mb-2">Camere Confortevoli</h3>
            <p className="text-gray-600">
              Ogni camera è arredata con stile e comfort
            </p>
          </motion.div>

          <motion.div
            className="text-center p-6 bg-white rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
          >
            <motion.div>
              <Euro className="w-12 h-12 mx-auto text-pink-600 mb-4" />
            </motion.div>
            <h3 className="text-xl font-bold mb-2">Prezzi Competitivi</h3>
            <p className="text-gray-600">Tariffe trasparenti e convenienti</p>
          </motion.div>

          <motion.div
            className="text-center p-6 bg-white rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
          >
            <motion.div>
              <MapPin className="w-12 h-12 mx-auto text-pink-600 mb-4" />
            </motion.div>
            <h3 className="text-xl font-bold mb-2">A due passi dal centro</h3>
            <p className="text-gray-600">
              Posizione strategica nel cuore del paese
            </p>
          </motion.div>
        </motion.div>
      </motion.section>
    </div>
  );
};

const GalleryPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  
  const gallery = [
    Camera1,
    Camera2,
    Camera3,
    Bagno,
    Portone,
    FuroiPrimavera,
    Apparecchiato,
    Sala1,
    Fuori,
  ];

  const closeModal = () => setSelectedImage(null);

  return (
    <div id="gallery" className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center mb-12">
        La Nostra Galleria
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {gallery.map((foto, i) => (
          <motion.div
            key={i}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: i * 0.1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setSelectedImage(foto)}
            className="aspect-square bg-gray-200 rounded-lg overflow-hidden cursor-pointer"
          >
            <img
              src={foto}
              alt={"Gallery image " + (i + 1)}
              className="w-full h-full object-cover"
            />
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
            className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.5 }}
              className="relative max-w-4xl w-full"
              onClick={e => e.stopPropagation()}
            >
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <img
                src={selectedImage}
                alt="Selected gallery image"
                className="w-full h-auto rounded-lg shadow-xl"
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ContattiPage = () => {
  return (
    <div id="contatti" className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-center mb-12">Contattaci</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div>
            <h3 className="text-xl font-bold mb-2">Informazioni</h3>
            <p className="text-gray-600">B&B Amarena Via Belvedere, 37 </p>
            <p className="text-gray-600">
              42033 Carpineti ( Reggio Emilia ), Italia
            </p>
            <p className="text-gray-600">Tel: +39 334 781 3920</p>
            <p className="text-gray-600">
              Email: bbamarena.carpineti@gmail.com
            </p>
          </div>

          {/*  <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold mb-4">Richiedi Informazioni</h3>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nome e Cognome
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Messaggio
              </label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows={4}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700 transition-colors"
            >
              Invia Messaggio
            </button>
          </form>
        </div> */}
        </div>
      </div>
    </div>
  );
};


const AppartamentiPage = () => {
  const camere = [
    {
      nome: "Primo Piano",
      descrizione: "Appartamento per due persone",
      caratteristiche: [
        "Letto matrimoniale",
        "Bagno privato",
        "Angolo Cottura",
      ],
      foto: Camera3,
      disponibile: false
    },
    {
      nome: "Secondo Piano",
      descrizione:
        "Appartamento con un massimo di 5 posti letto diviso in due camere",
      caratteristiche: [
        "Letto matrimoniale",
        "Due bagni",
        "Due letti singoli",
        "Angolo Cottura",
      ],
      foto: Camera1,
      disponibile: true
    },
  ];

  const serviziPrincipali = [
    { nome: "Parcheggio", icona: <ParkingSquare /> },
    { nome: "Wi-Fi in tutta la struttura", icona: <Wifi /> },
    { nome: "Ampio spazio esterno", icona: <TreePine /> },
    { nome: "TV", icona: <Tv /> },
  ];

  return (
    <div id="appartamenti" className="container mx-auto px-4 py-16 space-y-16">
      <h2 className="text-3xl font-bold text-center mb-12">Le Nostre Camere</h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {camere.map((camera) => (
            <motion.div
              key={camera.nome}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-200 relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
            >
              <div className="relative">
                <img
                  src={camera.foto}
                  alt="Camera Background"
                  className={`h-48 w-full object-cover ${!camera.disponibile ? 'filter grayscale' : ''}`}
                />
                {!camera.disponibile && (
                  <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur-sm flex items-center justify-center">
                    <div className="text-center">
                      <div className="border border-white/50 bg-black/30 text-white px-6 py-2 rounded-full text-lg font-light tracking-wider">
                        Non Disponibile
                      </div>
                      <p className="text-white/80 text-sm mt-2 font-light">
                        Temporaneamente occupato
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className={`p-6 ${!camera.disponibile ? 'opacity-75' : ''}`}>
                <h3 className="text-xl font-semibold mb-2">{camera.nome}</h3>
                <p className="text-gray-600 mb-4">{camera.descrizione}</p>
                <ul className="space-y-2 mb-4">
                  {camera.caratteristiche.map((caratteristica) => (
                    <li
                      key={caratteristica}
                      className="flex items-center space-x-2"
                    >
                      <span className="w-2 h-2 bg-pink-600 rounded-full" />
                      <span>{caratteristica}</span>
                    </li>
                  ))}
                </ul>
                <p className="text-lg font-bold text-pink-600">
                  {camera.prezzo}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="bg-gradient-to-r from-gray-100 to-gray-50 rounded-lg shadow-md p-10">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Servizi Principali
        </h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 text-gray-700">
          {serviziPrincipali.map((servizio) => (
            <motion.li
              key={servizio.nome}
              className="flex flex-col items-center bg-white p-4 rounded-lg shadow-sm transform hover:scale-105 transition-transform duration-150"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
            >
              <div className="text-pink-600 text-3xl mb-3">
                {servizio.icona}
              </div>
              <span className="font-semibold">{servizio.nome}</span>
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TariffePage = () => {
  const tariffe = [
    {
      tipo: "Base",
      prezzo: "40€/persona a notte",
      caratteristiche: [
        "Appartamento completo",
        "Colazione inclusa",
        "Wi-Fi gratuito",
      ],
    },
    {
      tipo: "Se occupata singolarmente",
      prezzo: "45€/notte",
      caratteristiche: [
        "Appartamento completo",
        "Colazione inclusa",
        "Wi-Fi gratuito",
      ],
    },
  ];

  return (
    <div id="tariffe" className="container mx-auto px-4 py-16 space-y-16">
      <h2 className="text-3xl font-bold text-center mb-12">
        Le Nostre Tariffe
      </h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl">
          {tariffe.map((tariffa) => (
            <motion.div
              key={tariffa.tipo}
              className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-200"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
            >
              <h3 className="text-xl font-bold mb-4">{tariffa.tipo}</h3>
              <p className="text-3xl font-bold text-pink-600 mb-6">
                {tariffa.prezzo}
              </p>
              <ul className="space-y-3">
                {tariffa.caratteristiche.map((caratteristica) => (
                  <li
                    key={caratteristica}
                    className="flex items-center space-x-2"
                  >
                    <span className="w-2 h-2 bg-pink-600 rounded-full" />
                    <span>{caratteristica}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Layout>
      <HomePage />
      <AppartamentiPage />
      <TariffePage />
      <GalleryPage />
      <ContattiPage />
    </Layout>
  );
};

export default App;
